
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・MT】購入前に知っておきたい！NSXの特徴やおすすめのモデルとは？
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            ホンダ・NSXは、生産が終了した今でも、中古車市場で世界的に人気を集めている車種です。NSXの特徴や人気モデルを理解したうえで、お気に入りのMT車を探すことをおすすめします。NSXなど中古車の購入をご検討中の方は、car shop MIXにご相談ください。
            <div class="section-title">
              <span >NSXの特徴やおすすめのモデルについて</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      中古車市場で高い人気を誇る国産車はいくつかありますが、その中でも人気のMT車がホンダ・NSXです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      こちらでは、NSXの概要や特徴、おすすめモデルをご紹介いたします。
                    </p>
                    <h3 class="heading-number" data-number="◆">NSXとは？</h3>
                    <p class="u-m0 u-pb1em">
                      ホンダ・NSXとは、1990年にリリースされ、2005年まで販売されたミッドシップのスポーツカーです。最近では、新型のNSXが2017年に開発され、ハイブリッドエンジンを搭載した進化版がリリースされたことで注目を集めました（2022年に生産終了）。
                    </p>
                    <p class="u-m0 u-pb1em">
                      1984年にホンダが開発をスタートした初代NSXの設計には、当時のF1チャンピオン、アイルトン・セナの意見が取り入れられたという逸話があり、走行性にこだわり抜いて開発されたといわれています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      NSXの名前は「New Sportscar experimental」の略で、その名のとおり、ホンダの新たなスポーツカーへの挑戦を意味していたのです。
                    </p>
                    <h3 class="heading-number" data-number="◆">NSXの特徴</h3>
                    <p class="u-m0 u-pb1em">
                      次にホンダ・NSXの特徴をご紹介いたします。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/honda-nsx-car.jpg" alt="Image">
                  </div>
              </div>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>業界最高峰のスポーツカー</h4>
              <p class="u-m0 u-pb1em">
                ホンダ・NSXは、その性能と革新的な技術で業界最高峰のスポーツカーとして認識されています。初代NSXは全アルミニウム製モノコックボディ、中央配置のV6エンジン、洗練されたエアロダイナミクスを採用しています。そのため、スーパーカーでありながら、日常の利便性を兼ね備えたモデルとして人気があります。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>ワイド＆ローのエクステリア</h4>
              <p class="u-m0 u-pb1em">
                NSXは、空気抵抗を最大限に抑えたワイド＆ローのエクステリアが特徴です。そのデザインは、空気の流れを最適化し、高速走行時の高い安定性を実現します。また、スタイリッシュなボディデザインも特徴的です。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>走行性を追求した低重心化と居住空間を実現</h4>
              <p class="u-m0 u-pb1em">
                NSXは、走行性能を追求しながら、居住空間もしっかりと確保しているのが特徴的です。低重心化により高速域でも安定した走行が可能となり、運転席と助手席の間隔を広く取ることで、広々とした居住空間を実現していますので快適なドライブを楽しめるでしょう。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>ホールド性の高いスポーツシートを採用</h4>
              <p class="u-m0 u-pb1em">
                NSXにはホールド性の高いスポーツシートが採用されています。これは、激しいコーナリングや高速走行でもドライバーをしっかりと支え、運転に集中できるようにするためです。また、デザインと材質は、高級感だけでなく、長時間のドライブでも快適さを保てます。
              </p>
              <h3 class="heading-number" data-number="◆">NSXのおすすめモデル</h3>
              <p class="u-m0 u-pb1em">
                NSXのモデルの中でも特におすすめなのは、2017年に登場した「NSX Type R」です。これは初代NSXの最高峰モデル「Type R」の名を継承したモデルで、よりスポーティな走行性能とエクステリアデザインを持っています。軽量化されたボディと専用サスペンション、強化されたブレーキなどにより、サーキット走行でのパフォーマンスが大幅に向上しました。
              </p>
              <p class="u-m0 u-pb1em">
                その他にも、初代NSXであるNA2モデルやType Rはパフォーマンスの高さや希少性から高値で取引されています。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                人気のMT車や低走行車をお探しの方は、car shop MIXまでご相談ください。国産車だけでなく、外車も含めて幅広い車種のMT車を取り揃えています。また、自動車板金・塗装・カークリーニング・オイル交換などの車両メンテナンスも行っています。自動車に関することでお困りでしたら、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span>NSXの中古車を購入するならcar shop MIXへ</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      MT車の中でも人気のNSXは、ハイパワーな走行性能を実現したスポーツカーとして、国内外から注目されている車種です。新型のNSXがリリースされたことで、旧モデルのNSXの需要が高まっています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      依然として高い人気を誇るモデルですので、高ければ数千万円以上で取引されるケースも珍しくありません。予算と相談しながら、少しでも状態のよい中古車を選ぶことをおすすめします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      納得のいく一台と巡り合うためには、取り扱う車種や在庫が豊富な販売店を探すことが大切です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      人気のMT車を購入したいとお考えの方は、茨城県坂東市にあるcar shop MIXにお任せください。中古の高級車をはじめ、人気のMT車など幅広いジャンルの自動車を豊富に取り揃えています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      万が一、公式サイトの在庫状況にお目当ての車がなかった場合も、豊富なネットワークを活かして、ご希望の車種をお探しします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車探しでお困りのことがございましたら、ぜひお気軽にご相談ください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="走行する赤色の中古車" src="@/assets/img/column-maker-mt/running-red-used-car.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'MT車や低走行車をお探しなら中古車販売のcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-08.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
